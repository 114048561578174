function logout() {
    localStorage.removeItem('token');
    window.location.replace('/');
}
function isAuthenticated() {

    const tObject = JSON.parse(localStorage.getItem('token'));
    if (!tObject) {
        return false
    }
    const userid = tObject.token.userid
    const expiresIn = tObject.token.expiresIn
    const token = tObject.token.token;
    let now = new Date()
    if (tObject && now <= new Date(expiresIn) && userid && token) {
        return true
    }
    return false
}

export { isAuthenticated, logout };