import React, { Suspense } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom';
import { isAuthenticated } from './helper.js'

const Head = React.lazy(() => import('./components/Header'));
const Center = React.lazy(() => import('./components/Center'));
const CV = React.lazy(() => import('./pages/CV'));
const FourZeroFour = React.lazy(() => import('./pages/404'));
const Skills = React.lazy(() => import('./pages/Skills'));
const Projects = React.lazy(() => import('./pages/Projects'));
const Impressum = React.lazy(() => import('./pages/Impressum'));
const News = React.lazy(() => import('./pages/News'));
const Datenschutz = React.lazy(() => import('./pages/Datenschutz'));
const Footer = React.lazy(() => import('./components/Footer'));
const Blog = React.lazy(() => import('./components/Blog'));
const Login = React.lazy(() => import('./pages/auth/Login'));
const Admin = React.lazy(() => import('./pages/auth/Admin'));

function App(props) {
  window.scrollTo(0, 0)
  return (
    <div>
      <Suspense fallback={<div>&nbsp;</div>}>
        <Head isLoggedIn={isAuthenticated()} />

        {!props.route && <Center />}
        {props.route === 'cv' && <CV />}
        {props.route === 'projects' && <Projects />}
        {props.route === 'projekte' && <Projects />}
        {props.route === 'news' && <News />}
        {props.route === 'skills' && <Skills />}
        {props.route === 'blog' && <Blog />}
        {props.route === 'datenschutz' && <Datenschutz />}
        {props.route === 'impressum' && <Impressum />}
        {props.route === 'login' && <Login />}
        {props.route === 'admin' && <Admin />}
        {props.route === '404' && <FourZeroFour />}


        <Footer />
        <CookieConsent
          location="bottom"
          buttonText="Einverstanden"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "black", fontSize: "13px" }}
          expires={150}
        >
          Diese Webseite nutzt Cookies. Hoffentlich ist das ok für Dich?{" "}
          <span style={{ fontSize: "10px" }}><Link to="/datenschutz">Hier steht was über den Datenschutz</Link></span>
        </CookieConsent>
      </Suspense>
    </div>
  );
}

export default App;
