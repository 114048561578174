import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MyApp from './App';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import ReactGA from 'react-ga';
import { isAuthenticated, logout } from './helper.js'


/*2138211833*/
const trackingId = "2138211833"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);



const App = () => {
  return (<Router>
    <Switch>
      <Route exact path="/lebenslauf" render={() => <MyApp route="cv" />} />
      <Route exact path="/projects" render={() => <MyApp route="projects" />} />
      <Route exact path="/skills" render={() => <MyApp route="skills" />} />
      <Route exact path="/blog" render={() => <MyApp route="blog" />} />
      <Route exact path="/news" render={() => <MyApp route="news" />} />
      <Route exact path="/datenschutz" render={() => <MyApp route="datenschutz" />} />
      <Route exact path="/impressum" render={() => <MyApp route="impressum" />} />
      <Route exact path="/login" render={() => <MyApp route="login" />} />
      <Route exact path="/logout" render={() => logout()} />
      <Route path="/admin" render={() => (isAuthenticated() ? <MyApp route="admin" /> : <Redirect to="/login" />)} />
      <Route exact path="/" render={() => <MyApp />} />
      <Route exact path="*" render={() => <MyApp route="404" status={404} />} />
    </Switch>
  </Router>);
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


